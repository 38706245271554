<template>
  <div class="row my-4">
    <div class="col-lg-4 col-md-6 col-12 mt-4">
      <Card class="card dveb-grayheader-card value-card" style="height: 100%">
        <template #header>
          <div class="w-100 d-flex justify-content-between card-header-title">
            <p class="title">İhaleler</p>
            <router-link to="/properties/find"
              ><p  class="link">Tümünü Gör</p>
            </router-link>
          </div>
        </template>
        <div class="valuesBox-card" style="">
          <router-link to="/properties/find">
            <div class="value-box-detail d-flex justify-content-between">
              <div class="d-flex flex-column">
                <strong class="valueBox-title"
                  >Türkiye Finans'tan Maltepe Ritim İstanbul'da 4 Adet İş Yeri
                </strong>
                <span class="customer">Ayşe Ekinci</span>
                <span class="time">04 Temmuz 202 | 15.43 </span>
              </div>
              <div class="valueBox-description">
                <span class="valueBox-price">₺8.500.000.00</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="valuesBox-card" style="">
          <router-link to="/properties/find">
            <div class="value-box-detail d-flex justify-content-between">
              <div class="d-flex flex-column">
                <strong class="valueBox-title"
                  >Türkiye Finans'tan Maltepe Ritim İstanbul'da 4 Adet İş Yeri
                </strong>
                <span class="customer">Ayşe Ekinci</span>
                <span class="time">04 Temmuz 202 | 15.43 </span>
              </div>
              <div class="valueBox-description">
                <span class="valueBox-price">₺8.500.000.00</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="valuesBox-card" style="">
          <router-link to="/properties/find">
            <div class="value-box-detail d-flex justify-content-between">
              <div class="d-flex flex-column">
                <strong class="valueBox-title"
                  >Türkiye Finans'tan Maltepe Ritim İstanbul'da 4 Adet İş Yeri
                </strong>
                <span class="customer">Ayşe Ekinci</span>
                <span class="time">04 Temmuz 202 | 15.43 </span>
              </div>
              <div class="valueBox-description">
                <span class="valueBox-price">₺8.500.000.00</span>
              </div>
            </div>
          </router-link>
        </div>
      </Card>
    </div>

    <div class="col-lg-4 col-md-6 col-12 mt-4">
      <Card class="card dveb-grayheader-card value-card" style="height: 100%">
        <template #header>
          <div class="w-100 d-flex justify-content-between card-header-title">
            <p class="title">Ön Teklifler</p>
            <router-link to="/properties/find"
              ><a href="#" class="link">Tümünü Gör</a>
            </router-link>
          </div>
        </template>
        <div
          v-if="onTeklifler.length === 0"
          class="text-center mt-5 pt-5"
          style="font-weight: 500; font-size: 16px"
        >
          Teklif Yok
        </div>
        <div class="valuesBox-card" v-for="(teklif, i) in onTeklifler" key="i">
          <router-link to="/properties/find">
            <div class="value-box-detail d-flex justify-content-between">
              <div class="d-flex flex-column">
                <strong class="valueBox-title"
                  >Türkiye Finans'tan Maltepe Ritim İstanbul'da 4 Adet İş Yeri
                </strong>
                <span class="customer">Ayşe Ekinci</span>
                <span class="time">04 Temmuz 202 | 15.43 </span>
              </div>
              <div class="valueBox-description">
                <span class="valueBox-price">₺8.500.000.00</span>
              </div>
            </div>
          </router-link>
        </div>
      </Card>
    </div>

    <div class="col-lg-4 col-md-6 col-12 mt-4">
      <Card class="card dveb-grayheader-card value-card" style="height: 100%">
        <template #header>
          <div class="w-100 d-flex justify-content-between card-header-title">
            <p class="title">Hatırla</p>
            <router-link to="/reminders"
              ><p  class="link">Tümünü Gör</p>
            </router-link>
          </div>

        </template>
        <div class="valuesBox-card" style="">
          <router-link to="/properties/find">
            <div class="value-box-detail d-flex justify-content-between">
              <div class="d-flex flex-column">
                <strong class="valueBox-title"
                  >Türkiye Finans'tan Maltepe Ritim İstanbul'da 4 Adet İş Yeri
                </strong>
                <span class="customer">Ayşe Ekinci</span>
                <span class="time">04 Temmuz 202 | 15.43 </span>
              </div>
              <div class="valueBox-description">
                <span class="valueBox-price">₺8.500.000.00</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="valuesBox-card" style="">
          <router-link to="/properties/find">
            <div class="value-box-detail d-flex justify-content-between">
              <div class="d-flex flex-column">
                <strong class="valueBox-title"
                  >Türkiye Finans'tan Maltepe Ritim İstanbul'da 4 Adet İş Yeri
                </strong>
                <span class="customer">Ayşe Ekinci</span>
                <span class="time">04 Temmuz 202 | 15.43 </span>
              </div>
              <div class="valueBox-description">
                <span class="valueBox-price">₺8.500.000.00</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="valuesBox-card" style="">
          <router-link to="/properties/find">
            <div class="value-box-detail d-flex justify-content-between">
              <div class="d-flex flex-column">
                <strong class="valueBox-title"
                  >Türkiye Finans'tan Maltepe Ritim İstanbul'da 4 Adet İş Yeri
                </strong>
                <span class="customer">Ayşe Ekinci</span>
                <span class="time">04 Temmuz 202 | 15.43 </span>
              </div>
              <div class="valueBox-description">
                <span class="valueBox-price">₺8.500.000.00</span>
              </div>
            </div>
          </router-link>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onTeklifler: [
        // {
        //   name: "asd",
        //   date: "20.20.2023",
        //   price: "9.000₺",
        //   responsible: "rasim",
        // },
        // {
        //   name: "asadsd",
        //   date: "20.20.2023",
        //   price: "9.000₺",
        //   responsible: "rasim2",
        // },
        // {
        //   name: "asdsd",
        //   date: "20.20.2023",
        //   price: "9.000₺",
        //   responsible: "rasim3",
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.valuesBox-card {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
}
.valueBox-title {
  font-size: 12px;
  font-weight: 600;
  margin-right: 13px;
}
.customer {
  font-weight: 500;
  font-size: 15px;
  margin-top: 4px;
}
.valueBox-description .time {
  color: #999999;
  font-size: 12px;
  margin-top: 4px;
}

.valueBox-price {
  padding: 6px;
  background-color: #e7e7e7;
  border-radius: 10px;
  margin-top: 4px;
}
.card-header-title {
  align-items: center;
}
@media (max-width: 1280px) {
  .value-box-detail {
    flex-direction: column !important;
    gap: 1rem;
    .valueBox-price {
      display: block;
      text-align: center;
    }
  }
  .card-header-title {
    .title {
      font-size: 1.1rem !important;
      font-weight: 600 !important;
    }
    a {
      margin-left: 0 !important;
    }
  }
}
</style>
